import { createSlice } from "@reduxjs/toolkit";
import { getErrorMsg, makeRequest, BASE_API_URL } from "../utils";
import { showAlertAction } from "./alert";
import moment from "moment";

const slice = createSlice({
  name: "clients",
  initialState: {
    registeredClients: [],
    myClients: [],
    loading: false,
    errorMessage: "",
    filterDate: [moment().subtract(10, "days"), moment()],
    clients: {
      branches: {},
      areas: {},
      zones: {},
      states: {},
    },
    collaterals: {},
    staffs: {}
  },
  reducers: {
    addNewClientSuccess: (state, action) => {
      state.registeredClients.push(action.payload);
    },
    addNewClientFail: (state, action) => {
      state.errorMessage = action.payload;
    },
    updateFilterDate: (state, action) => {
      state.filterDate = action.payload;
    },
    deleteClient: (state, action) => {
      const indexOfItem = state.registeredClients.findIndex(
        (val) => val.id === action.payload
      );

      state.registeredClients.splice(indexOfItem, 1);
      state.registeredClients = [...state.registeredClients];
    },
    setClients: (state, action) => {
      state.clients = {
        ...state.clients,
        [action.payload?.orgType]: {
          ...state.clients?.[action.payload?.orgType],
          [action.payload?.id]: action.payload.data,
        },
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCollaterals: (state, action) => {
      state.collaterals = {
        ...state.collaterals,
        [action?.payload?.orgType]: {
          ...state.collaterals?.[action.payload?.orgType],
          [action.payload.orgId]: action.payload.data
        }
      }
    },
    setStaffs: (state, action) => {
      state.staffs = {
        ...state.staffs,
        [action?.payload?.orgType]: {
          ...state.staffs?.[action.payload?.orgType],
          [action.payload.orgId]: action.payload.data
        }
      }
    }
  },
});

export default slice.reducer;

const { addNewClientSuccess, updateFilterDate, setClients, setLoading, setCollaterals, setStaffs } =
  slice.actions;

export const setClientDateFilterAction = (dateRange) => async (dispatch) => {
  try {
    dispatch(updateFilterDate(dateRange));
  } catch (error) {
    return getErrorMsg(error?.message);
  }
};

export const addNewClient = (client) => async (dispatch) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/clients/`,
      "POST",
      client
    );

    dispatch(addNewClientSuccess(res));

    dispatch(
      showAlertAction({
        message: "Client has been successfully added to system 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const updateClient =
  (id, data, method = "PUT") =>
  async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/${id}/`,
        method,
        data
      );

      dispatch(addNewClientSuccess(res));

      dispatch(
        showAlertAction({
          message: "Client has been successfully updated",
          level: "success",
          isVisible: true,
        })
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const addNewGuarantor = (guarantor) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/guarantors/`,
      "POST",
      guarantor
    );

    dispatch(
      showAlertAction({
        message: "Guarantor has been successfully added to system 🎉",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const updateGuarantor =
  (id, data, method = "PUT") =>
  async (dispatch) => {
    try {
      await makeRequest(
        `${BASE_API_URL}/accounts/guarantors/${id}/`,
        method,
        data
      );

      dispatch(
        showAlertAction({
          message: "Guarantor has been successfully updated",
          level: "success",
          isVisible: true,
        })
      );
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllGuarantor =
  (queryString = "") =>
  async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/guarantors/?${queryString}`,
        "GET"
      );

      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getClientById = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/clients/${id}/`,
      "GET"
    );
    return res;
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve client data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const searchClientsByName = (name) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/clients/?search_name=${name}`,
      "GET"
    );
    return res;
  } catch (e) {
    dispatch(
      showAlertAction({
        message: "Unable to retrieve client data please try again",
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getClientsWithParams =
  (orgType, id, queryString) => async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/slim/?${queryString}`,
        "GET"
      );

      dispatch(
        setClients({
          orgType,
          id,
          data: res,
        })
      );

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        showAlertAction({
          message:
            error?.message || "Unable to retrieve client data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getSlimClientsWithParams =
  (queryString) => async (dispatch, getState) => {
    "Get a much smaller data of clients, this helps reduce wait time";
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/clients/slim/?${queryString}`,
        "GET"
      );
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message:
            error?.message || "Unable to retrieve client data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteClientAction = (id) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/accounts/clients/${id}/`, "DELETE");

    dispatch(
      showAlertAction({
        message: "Client has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  } catch (e) {
    console.log(e);
    dispatch(
      showAlertAction({
        message: "Client has been deleted successfully",
        level: "success",
        isVisible: true,
      })
    );
  }
};

/**
 * @deprecated use `getStaffsWithParams`
 */
export const getAllCos = (position) => async (dispatch, getState) => {
  try {
    const { user } = getState().users;
    const userPosition = !!position ? position : user.position;

    switch (userPosition) {
      case "CO":
        return [user];
      case "BM":
        return await makeRequest(
          `${BASE_API_URL}/accounts/users/?branch=${user.branch?.id}&position=CO&${queryString}`
        );
      case "AM":
        return await makeRequest(
          `${BASE_API_URL}/accounts/users/?area=${user.area?.id}&position=CO&${queryString}`
        );
      case "ZM":
        return await makeRequest(
          `${BASE_API_URL}/accounts/users/?zone=${user.zone?.id}&position=CO&${queryString}`
        );
      case "SM":
        return await makeRequest(
          `${BASE_API_URL}/accounts/users/?state=${user.state?.id}&position=CO&${queryString}`
        );
      case "DR":
        return await makeRequest(
          `${BASE_API_URL}/accounts/users/?position=CO&${queryString}`
        );
      default:
        return [];
    }
  } catch (e) {
    return [];
  }
};

export const getStaffsWithParams =
  (queryString, position = "CO", orgType, orgId) =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/users/?position=${position}&${queryString}`
      );

      dispatch(setStaffs({ orgType, orgId, data: res }))
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message:
            error?.message || "Unable to retrieve client data please try again",
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const getAllunions = (queryString) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/unions/?${queryString}`,
      "GET"
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getUnion = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/unions/${id}/`,
      "GET"
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getUnionsMembers = (id) => async (dispatch, getState) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/unions/${id}/members`,
      "GET"
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error?.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addNewUnion = (data) => async (dispatch) => {
  try {
    const res = await makeRequest(
      `${BASE_API_URL}/accounts/unions/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Union has been successfully added to system.",
        level: "success",
        isVisible: true,
      })
    );
    return res;
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const addAdditionalSavingsAction = (data) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/additionalsavings/`,
      "POST",
      data
    );

    dispatch(
      showAlertAction({
        message: "Additional savings has been successfully added.",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllAdditionalSavingsAction =
  (queryString) => async (dispatch) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/additionalsavings/?${queryString}`
      );

      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const deleteAdditionaSavingsAction = (id) => async (dispatch) => {
  try {
    await makeRequest(
      `${BASE_API_URL}/accounts/additionalsavings/${id}/`,
      "DELETE"
    );

    dispatch(
      showAlertAction({
        message: "Successfully deleted",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const refreshSavingsAction = (id, new_balance) => async (dispatch) => {
  try {
    await makeRequest(`${BASE_API_URL}/accounts/emptysavings/`, "POST", {
      client_id: id,
      new_balance: new_balance,
    });

    dispatch(
      showAlertAction({
        message: "Refresh successful",
        level: "success",
        isVisible: true,
      })
    );
  } catch (error) {
    dispatch(
      showAlertAction({
        message: error.message,
        level: "danger",
        isVisible: true,
      })
    );
  }
};

export const getAllCreateCollateralsAction =
  ({ querySet, type = "GET", data, orgType, orgId }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true))
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/collaterals/${
          querySet ? "?" + querySet : ""
        }`,
        type,
        data
      );

      if (type === "POST") {
        dispatch(
          showAlertAction({
            message: "Collateral recorded successfully.",
            level: "success",
            isVisible: true,
          })
        );
      } else {
        dispatch(setCollaterals({orgType, orgId, data: res}))
      }

      dispatch(setLoading(false))
      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };

export const collateralRUDAction =
  (id, type = "GET", data) =>
  async (dispatch, getState) => {
    try {
      const res = await makeRequest(
        `${BASE_API_URL}/accounts/collaterals/${id}/`,
        type,
        data || undefined
      );

      return res;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: error?.message,
          level: "danger",
          isVisible: true,
        })
      );
    }
  };


  export const uploadToS3Action = (file, directory="") => async (dispatch) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('directory', directory);
  
      const res = await fetch(`${BASE_API_URL}/accounts/upload-image/`, {
        method: 'POST',
        body: formData,
      });
  
      const data = await res.json();
      return data?.url;
    } catch (error) {
      dispatch(
        showAlertAction({
          message: "Error uploading file",
          level: "danger",
          isVisible: true,
        })
      );
    }
  }