import {
  Descriptions,
  Input,
  Select,
  Collapse,
  Switch
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { showAlertAction } from "../store/alert";
import { sellRetrieveAssetAction, getSiezedAsset } from "../store/org";
import { ImageUpload } from "./ImageUpload";
import { cleanedImage } from "utils";

export const STATUS = ["Sold", "Retrieved"];

const SeizedAssetDetail = () => {
  const [asset, setAsset] = useState();
  const [newStatus, setNewStatus] = useState();
  const [retrievalAmount, setRetrievalAmount] = useState();
  const [retrievalQuantity, setRetrievalQuantity] = useState();
  const [salesAmount, setSalesAmount] = useState();
  const [salesQuantity, setSalesQuantity] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [showFunddailypay, setShowFunddailypay] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  const handleImageUpload = useCallback((url) => {
    dispatch(getSiezedAsset(id, "PATCH", { image: url })).then((res) => setAsset(res));
  }, []);

  const handleImageRemove = useCallback(() => {
    dispatch(getSiezedAsset(id, "PATCH", { image: null })).then((res) => setAsset(res));
  })

  const handleUpdateStatus = () => {
    if (
      (newStatus === "Retrieved" && !retrievalAmount) ||
      (newStatus === "Sold" && !salesAmount)
    ) {
      dispatch(
        showAlertAction({
          message: "Please enter amount",
          level: "danger",
          isVisible: true,
        })
      );
    }

    let data;
    if (newStatus === "Retrieved") {
      data = {
        amount: retrievalAmount,
        date: moment().format("YYYY-MM-DD"),
        quantity: retrievalQuantity,
        asset: id,
        fund_monthly_pay: showFunddailypay,
      };

      dispatch(sellRetrieveAssetAction("retrieval", data)).then(() => {
        setTriggerRefresh((current) => !current);
      });
    } else {
      data = {
        amount: salesAmount,
        date: moment().format("YYYY-MM-DD"),
        quantity: salesQuantity,
        asset: id,
        fund_monthly_pay: showFunddailypay,
      };

      dispatch(sellRetrieveAssetAction("sell", data)).then(() => {
        setTriggerRefresh((current) => !current);
        setRetrievalAmount(undefined);
        setRetrievalQuantity(undefined);
        setSalesAmount(undefined);
        setSalesQuantity(undefined);
      });
    }
  };

  useEffect(() => {
    dispatch(getSiezedAsset(id, "GET")).then((res) => setAsset(res));
  }, [triggerRefresh]);

  return (
    <div>
      <Row>
        <Col>
          <ImageUpload
            uploadDir="seized_assets"
            image={cleanedImage(asset?.image)}
            onImageUpload={handleImageUpload}
            onDeleteImage={handleImageRemove}
          />
        </Col>
      </Row>
      <Descriptions
        title={`Asset owned by: ${asset?.owner?.fullname}`}
        className="mt-3"
      >
        <Descriptions.Item label="Date" span={2}>
          {asset?.date || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Name" span={2}>
          {asset?.name || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Amount owed" span={2}>
          {asset?.amount || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Quantity of siezed items" span={2}>
          {asset?.quantity || "-"}
        </Descriptions.Item>
        <Descriptions.Item label="Status of item" span={2}>
          {asset?.status?.map((val, index) =>
            index === asset.status?.length - 1 ? val : `${val}, `
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Serial Number" span={2}>
          {asset?.serial_number || "-"}
        </Descriptions.Item>

        {asset?.retrieval_date && (
          <>
            <Descriptions.Item label="Date Retrieved" span={2}>
              {asset?.retrieval_date || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Amount paid for retrieval" span={2}>
              {Number(asset?.retrieval_amount)?.toLocaleString() || "-"}
            </Descriptions.Item>

            <Descriptions.Item label="Retrieved Quantity" span={2}>
              {asset?.retrieved_quantity || "-"}
            </Descriptions.Item>
          </>
        )}

        {asset?.sales_date && <></>}
      </Descriptions>

      <hr />

      <Collapse bordered={false} className="site-collapse-custom-collapse">
        <Collapse.Panel
          header={
            <h6 className="mb-0">
              Sales ({asset?.sold_asset?.length}) -{" "}
              {`₦${Number(asset?.amount_sold || 0).toLocaleString()}`}
            </h6>
          }
          key="1"
          className="site-collapse-custom-panel"
        >
          {asset?.sold_asset?.map((val) => (
            <Descriptions title={val?.date} className="mb-3">
              <Descriptions.Item label="Date Sold" span={2}>
                {val?.date || "-"}
              </Descriptions.Item>

              <Descriptions.Item label="Sales amount" span={2}>
                {Number(val?.amount)?.toLocaleString() || "-"}
              </Descriptions.Item>

              <Descriptions.Item label="Sold Quantity" span={2}>
                {val?.quantity || "-"}
              </Descriptions.Item>
              <hr />
            </Descriptions>
          ))}
        </Collapse.Panel>
        <Collapse.Panel
          header={
            <h6 className="mb-0">
              Retrievals ({asset?.retrieved_asset?.length}) -{" "}
              {`₦${Number(asset?.amount_retrieved || 0).toLocaleString()}`}
            </h6>
          }
          key="2"
          className="site-collapse-custom-panel"
        >
          {asset?.retrieved_asset?.map((val) => (
            <Descriptions title={val?.date} className="mb-3">
              <Descriptions.Item label="Date Sold" span={2}>
                {val?.date || "-"}
              </Descriptions.Item>

              <Descriptions.Item label="Sales amount" span={2}>
                {Number(val?.amount)?.toLocaleString() || "-"}
              </Descriptions.Item>

              <Descriptions.Item label="Sold Quantity" span={2}>
                {val?.quantity || "-"}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </Collapse.Panel>
      </Collapse>

      {asset?.status?.includes("Held") && (
        <>
          <hr />

          <h6>Update Status</h6>
          <Row>
            <Col md="6">
              <Select
                showSearch
                placeholder="Select new status"
                optionFilterProp="children"
                className="custom-input"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                allowClear
                onSelect={(val) => setNewStatus(val)}
              >
                {STATUS.map((val) => (
                  <Select.Option value={val} key={val}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            {newStatus === "Retrieved" && (
              <>
                <Col md="6">
                  <Input
                    placeholder="Enter amount paid for retrieval"
                    type="number"
                    className="form-control"
                    value={retrievalAmount}
                    onChange={(e) => setRetrievalAmount(e.target.value)}
                  />
                </Col>

                <Col md="6" className="mt-2">
                  <Input
                    placeholder="Enter quantity retrieved"
                    type="number"
                    className="form-control"
                    value={retrievalQuantity}
                    onChange={(e) => setRetrievalQuantity(e.target.value)}
                  />
                </Col>
              </>
            )}

            {newStatus === "Sold" && (
              <>
                <Col md="6">
                  <Input
                    placeholder="Enter amount sold"
                    type="number"
                    className="form-control"
                    value={salesAmount}
                    onChange={(e) => setSalesAmount(e.target.value)}
                  />
                </Col>

                <Col md="6" className="mt-2">
                  <Input
                    placeholder="Enter quantity sold"
                    type="number"
                    className="form-control"
                    value={salesQuantity}
                    onChange={(e) => setSalesQuantity(e.target.value)}
                  />
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col className="mt-3">
              <div className="d-flex">
                <Switch
                  defaultChecked={false}
                  value={showFunddailypay}
                  onChange={setShowFunddailypay}
                  className="mr-2"
                />
                <p>Fund daily pay</p>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Button
                variant="primary"
                className="px-5"
                disabled={!newStatus}
                onClick={() => handleUpdateStatus()}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export { SeizedAssetDetail };
