import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import ReactQuill from "react-quill";
import { useParams } from "react-router";
import { Descriptions, Tabs } from "antd";
import { Link } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";

import { getClientById, updateClient, getAllCreateCollateralsAction } from "../store/clients";
import { getLoansWithParams } from "../store/loans";

import { ImageUpload, ModifiedAntTable } from ".";
import { cleanedImage, getStatusClassName } from "../utils";

const { TabPane } = Tabs;

const ClientProfile = () => {
  const [clientData, setClientData] = useState();
  const [clientLoans, setClientLoans] = useState();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [kycDetail, setKycDetail] = useState();
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [activeView, setActiveView] = useQueryParam("view", StringParam);

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    users: { user },
    clients: { collaterals },
  } = useSelector((state) => ({
    users: state.users,
    clients: state.clients,
  }));

  const handleImage = async (url) => {
    dispatch(updateClient(id, { image: url })).then((res) => {
      setClientData(res);
    });
  };

  const handleSetkYC = async () => {
    dispatch(updateClient(id, { kyc: String(kycDetail) })).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    if (window.innerWidth > 700) {
      setIsMobile(false);
    }
  }, []);

  useEffect(async () => {
    setLoading(true);
    const res = await dispatch(getClientById(id));
    setClientData(res);

    dispatch(getLoansWithParams(`client_id=${id}`)).then((result) => {
      setClientLoans(result);
      setLoading(false);
    });

    dispatch(
      getAllCreateCollateralsAction({
        querySet: `client_id=${id}`,
        orgId: id,
        orgType: "client_id",
      })
    );
  }, [triggerRefresh]);

  useEffect(() => {
    setKycDetail(clientData?.kyc);
  }, [clientData]);

  const collateralCollumn = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount (₦)",
      dataIndex: "amount",
      render: (text, record, index) => (
        <div>{Number(record?.amount).toLocaleString()}</div>
      ),
    },
    {
      title: "Associated Loan",
      dataIndex: "loan",
      render: (text, record, index) =>
        record?.loan_id && record?.client_name ? (
          <Link to={`/loans/${record?.loan_id}`}>
            <i className="fa fa-link" aria-hidden="true"></i>{" "}
            {`${record?.client_name}`}
          </Link>
        ) : (
          <div>None</div>
        ),
    },
  ];

  const loanColumns = [
    {
      title: "S/N",
      dataIndex: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Request date",
      dataIndex: "creation_date",
    },
    {
      title: "Amount ₦",
      dataIndex: "loan_amount",
      render: (text, record) => <>{Number(text).toLocaleString()}</>,
    },
    {
      title: "Monthly Payment Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            record?.current_month_stat === "PAID"
              ? "badge-success"
              : "badge-warning"
          )}
        >
          {record?.current_month_stat}
        </div>
      ),
    },
    {
      title: "Loan Status",
      render: (text, record) => (
        <div
          className={classNames(
            "status-badge badge badge-sm",
            getStatusClassName(record?.loan_status)
          )}
        >
          {record?.loan_status}
        </div>
      ),
    },
    {
      title: "View detail",
      render: (text, record) => (
        <Link to={`/loans/${record.id}`}>
          <i className="fa fa-link" aria-hidden="true"></i>{" "}
        </Link>
      ),
    },
  ];

  return (
    <Container fluid>
      <h6 className="mb-3">
        {clientData?.title} {clientData?.surname} {clientData?.firstname}
      </h6>
      <Tabs
        tabPosition={isMobile ? "top" : "left"}
        defaultActiveKey={activeView ?? "activeView"}
        className="loan-detail-tabs"
        onChange={(activeKey) => setActiveView(activeKey)}
        activeKey={activeView}
      >
        <TabPane tab="Client Detail" key="client_detail">
          <Row>
            <Col>
              <ImageUpload
                uploadDir={"clients"}
                image={cleanedImage(clientData?.image)}
                onImageUpload={handleImage}
                onDeleteImage={() => getClientById(null)}
              />
            </Col>
          </Row>
          <Descriptions
            title={`${clientData?.title} ${clientData?.surname} ${clientData?.firstname}`}
            layout="vertical"
            className="mt-3"
          >
            <Descriptions.Item label="Credit Officer">
              {clientData?.co_fullname}
            </Descriptions.Item>

            <Descriptions.Item label="House Address">
              {clientData?.house_address}
            </Descriptions.Item>

            <Descriptions.Item label="Office Address">
              {clientData?.office_address}
            </Descriptions.Item>

            <Descriptions.Item label="Phone Number 1">
              {clientData?.phone_number1}
            </Descriptions.Item>

            <Descriptions.Item label="Phone Number 2">
              {clientData?.phone_number2}
            </Descriptions.Item>

            <Descriptions.Item label="Marital Status">
              {clientData?.marital_status}
            </Descriptions.Item>

            <Descriptions.Item label="Number of children">
              {clientData?.number_of_children}
            </Descriptions.Item>

            <Descriptions.Item label="NIN">{clientData?.nin}</Descriptions.Item>

            <Descriptions.Item label="Branch Name">
              {clientData?.branch_name}
            </Descriptions.Item>
          </Descriptions>
        </TabPane>

        <TabPane tab="Loans" key="loans">
          <h5 className="mb-4">List of Loans</h5>

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={loanColumns}
            dataSource={clientLoans}
            bordered
            loading={loading}
            pagination={false}
          />
        </TabPane>

        <TabPane tab="Collaterals" key="collaterals">
          <h5 className="mb-4">List of Collaterals</h5>

          <ModifiedAntTable
            rowKey="id"
            scroll={{ x: "max-content" }}
            columns={collateralCollumn}
            dataSource={collaterals?.["client_id"]?.[id]}
            bordered
            pagination={false}
          />
        </TabPane>

        <TabPane tab="KYC Detiail" key="kyc">
          <ReactQuill
            theme="snow"
            defaultValue={kycDetail}
            value={kycDetail}
            onChange={setKycDetail}
            className="rich-text"
            style={{ height: "250px", marginBottom: "30px" }}
          />

          <button
            disabled={kycDetail === clientData?.kyc}
            className="btn btn-sm btn-primary mt-5"
            onClick={handleSetkYC}
          >
            Update KYC
          </button>
        </TabPane>
      </Tabs>
    </Container>
  );
};

export { ClientProfile };
